import { Waypoint } from 'react-waypoint';

export const WaypointWrapper = props => {
	// Methods
	const onEnter = data => {
		// logger.log('onEnter: ', data);
		if (props.onEnter) props?.onEnter(data);
	};

	const onLeave = data => {
		// logger.log('onLeave: ', data);
		if (props.onLeave) props?.onLeave(data);
	};

	// Render
	return <Waypoint {...props} onEnter={onEnter} onLeave={onLeave} />;
};

// Docs
// https://www.npmjs.com/package/react-waypoint
