import { Container } from '@playbooks/interface/grid';
import { Link } from '@playbooks/interface/links';
import {
	Prefooter,
	PrefooterActions,
	PrefooterBody,
	PrefooterSubtitle,
	PrefooterTitle,
} from '@playbooks/interface/prefooters';
import { gradientBgColor } from '@playbooks/interface/tailwind';

const AppPrefooter = () => {
	// Render
	return (
		<Prefooter border='' borderRadius='' display='' spacing='py-20' {...gradientBgColor}>
			<Container size='lg' spacing='px-4 lg:px-20'>
				<PrefooterBody align='text-center' space='space-y-4'>
					<PrefooterTitle>Join the Hybrid Source Movement</PrefooterTitle>
					<PrefooterSubtitle>
						Share your expertise and earn cash by submitting a premium software template to Playbooks. Not sure what to
						submit? Find inspiration via our bounty program.
					</PrefooterSubtitle>
				</PrefooterBody>
				<PrefooterActions display='flex-middle' spacing='mx-auto pt-8'>
					<Link
						size='md'
						nextIcon='chevron-right'
						bgColor='bg-gray-100'
						color='gray-700'
						hover='h:bg-white'
						href='/submit'>
						Submit Project
					</Link>
				</PrefooterActions>
			</Container>
		</Prefooter>
	);
};

export { AppPrefooter };
