import { useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { FormDivInput } from '@playbooks/interface/forms';
import { InputAppend, InputGroup, InputPrepend } from '@playbooks/interface/input-groups';
import { Tab, TabPane, TabPanes, Tabs, TabWrapper } from '@playbooks/interface/tabs';
import { useToast } from 'contexts';

const CLITabsWrapper = () => {
	const [activeTab, setActiveTab] = useState('npm');
	const [tabs, setTabs] = useState(['npm', 'yarn', 'pnpm']);
	const toast = useToast();
	// Methods
	const onClick = command => {
		window.navigator.clipboard.writeText(command);
		toast.showSuccess(200, 'Command copied!');
	};

	// Render
	return (
		<TabWrapper border='border' bgColor='bg-white dark:bg-gray-900' borderRadius='rounded-md'>
			<Tabs activeTab={activeTab} tabs={tabs} onSelect={setActiveTab} border='border-b'>
				{tabs.map((tab, i) => (
					<Tab key={i} size='xs' value={tab} active={tab === activeTab} onSelect={setActiveTab}>
						{tab.toUpperCase()}
					</Tab>
				))}
			</Tabs>
			<TabPanes>
				<TabPane value='npm' active={activeTab === 'npm'}>
					<InputGroup bgColor='' border='' width='w-full'>
						<InputPrepend icon='dollar' />
						<FormDivInput size='lg' variant='group' readOnly>
							npm install -g @playbooks/cli
						</FormDivInput>
						<InputAppend spacing='pr-2'>
							<BorderBtn size='icon' icon='clipboard' onClick={() => onClick('npm install -g @playbooks/cli')} />
						</InputAppend>
					</InputGroup>
				</TabPane>
				<TabPane value='yarn' active={activeTab === 'yarn'}>
					<InputGroup bgColor='' border='' width='w-full'>
						<InputPrepend icon='dollar' />
						<FormDivInput size='lg' variant='group' readOnly>
							yarn add -g @playbooks/cli
						</FormDivInput>
						<InputAppend spacing='pr-2'>
							<BorderBtn size='icon' icon='clipboard' onClick={() => onClick('yarn add -g @playbooks/cli')} />
						</InputAppend>
					</InputGroup>
				</TabPane>
				<TabPane value='pnpm' active={activeTab === 'pnpm'}>
					<InputGroup bgColor='' border='' width='w-full'>
						<InputPrepend icon='dollar' />
						<FormDivInput size='lg' variant='group' readOnly>
							pnpm add -g @playbooks/cli
						</FormDivInput>
						<InputAppend spacing='pr-2'>
							<BorderBtn size='icon' icon='clipboard' onClick={() => onClick('pnpm add -g @playbooks/cli')} />
						</InputAppend>
					</InputGroup>
				</TabPane>
			</TabPanes>
		</TabWrapper>
	);
};

export { CLITabsWrapper };
