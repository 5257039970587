import { useRouter } from 'next/router';

import { BreadcrumbItem, BreadcrumbLink, Breadcrumbs } from '@playbooks/interface/breadcrumbs';
import { capitalize } from 'utils';

const AppBreadcrumbs = () => {
	const router = useRouter();
	const paths = router.asPath.split('?')[0].split('/');
	const amendedPaths = paths.map((path, i) => ({
		title: parseInt(path) ? `#${path}` : path,
		href: paths.slice(0, i + 1).join('/'),
	}));
	const formattedPaths = amendedPaths.map((v, i) => ({ ...v, active: i + 1 === amendedPaths.length }));

	// Computed
	const isActive = v => {
		if (v)
			return {
				bgColor: '',
				bgOpacity: '',
			};
	};

	// Render
	return (
		<Breadcrumbs border='border-b' borderRadius='' spacing='px-5 py-3'>
			<BreadcrumbItem arrow={false}>
				<BreadcrumbLink icon='house' href='/' />
			</BreadcrumbItem>
			{formattedPaths.map((v, i) => (
				<BreadcrumbItem key={i} arrow={i !== 0}>
					{v.icon && <BreadcrumbLink icon={v.icon} href={v.href} active={isActive(v.active)} />}
					{v.title && (
						<BreadcrumbLink href={v.href} active={isActive(v.active)}>
							{capitalize(v.title)}
						</BreadcrumbLink>
					)}
				</BreadcrumbItem>
			))}
		</Breadcrumbs>
	);
};

export { AppBreadcrumbs };
