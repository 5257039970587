import { useRouter } from 'next/router';

import { BtnGroup } from '@playbooks/interface/button-groups';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';

const AppToggle = () => {
	const router = useRouter();
	const { content = '' } = router.query;

	// Methods
	const setRouterFilter = prop => {
		const query = { ...router.query, ...prop };
		router.push({ query }, undefined, { shallow: true });
	};

	// Render
	return (
		<BtnGroup divide='' width='w-full lg:w-auto'>
			<Span width='w-1/2'>
				<AccentBtn
					size='sm'
					borderRadius=''
					alt='toggle repo button'
					active={content === ''}
					onClick={() => setRouterFilter({ content: '' })}
					className='w-full'>
					Repos
				</AccentBtn>
			</Span>
			<Span width='w-1/2'>
				<AccentBtn
					size='sm'
					borderRadius=''
					alt='toggle bounty button'
					active={content === 'bounties'}
					onClick={() => setRouterFilter({ content: 'bounties' })}
					className='w-full'>
					Bounties
				</AccentBtn>
			</Span>
		</BtnGroup>
	);
};

export { AppToggle };
