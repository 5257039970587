import { useState } from 'react';

import { Container } from '@playbooks/interface/grid';
import { AboutWhatSection } from 'components/about/about-what-section';
import { AppToggle } from 'components/app/app-toggle';
import { BountyDisplaySection } from 'components/bounty/bounty-display-section';
import { IndexHero } from 'components/index/index-hero';
import { MktWrapper } from 'components/mkt/mkt-wrapper';
import { RepoDisplaySection } from 'components/repo/repo-display-section';
import { WaypointWrapper } from 'components/wrappers/waypoint-wrapper';
import { popularQuery } from 'queries';

const IndexRoute = ({ ssr, account, router, session, store, toast }) => {
	const [data, setData] = useState(ssr.popular?.data || {});
	const [waypoint, setWaypoint] = useState(false);
	const { content, layout } = router.query;

	// Computed
	const tailwind = layout === 'list' ? { card: { border: 'border', borderRadius: 'rounded-md', spacing: 'p-4' } } : {};

	// Methods
	const onEnter = () => setWaypoint(false);
	const onLeave = () => setWaypoint(true);

	// Render
	return (
		<MktWrapper seo={{ title: 'Playbooks | Software Templates, Starters & Themes' }}>
			<IndexHero />
			<WaypointWrapper topOffset='100px' onLeave={onLeave} onEnter={onEnter} />
			<Container size='xl'>
				{content === 'bounties' ? (
					<BountyDisplaySection
						type={layout || 'display'}
						title={<AppToggle />}
						api={{ view: 'recent' }}
						search={{}}
						rootApi={``}
						options={['views', 'layout']}
						views={['all', 'popular', 'trending', 'recent']}
						pagination={{ type: 'btn' }}
						tailwind={{ section: { border: 'border-b', spacing: 'pt-4 pb-8' }, list: tailwind }}
					/>
				) : (
					<RepoDisplaySection
						type={layout || 'display'}
						title={<AppToggle />}
						api={{ view: 'recent' }}
						search={{}}
						rootApi={``}
						options={['views', 'layout']}
						views={['all', 'popular', 'trending', 'recent']}
						pagination={{ type: 'btn' }}
						tailwind={{ section: { border: 'border-b', spacing: 'pt-4 pb-8' }, list: tailwind }}
					/>
				)}
				<AboutWhatSection />
			</Container>
		</MktWrapper>
	);
};

export async function getServerSideProps(props) {
	try {
		const popular = await popularQuery(props);
		return { props: { popular } };
	} catch (e) {
		return { redirect: { destination: '/welcome' } };
	}
}

export default IndexRoute;
